/* eslint-disable */


// Programatically generated on Fri Apr 04 2025 16:13:15 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'authoring_question_sets_v1',
    'attributes': {
      'published_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'file_upload': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'reorder_questions': {
        'type': 'binary_id[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': true
      },
      'is_new': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'subject_id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status_email': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'slug_id': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'status_message': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status_code': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'difficulty': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'category': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_guide_levels_v1': {
        'type': 'one-to-many'
      },
      'tags_v1': {
        'type': 'one-to-many'
      },
      'templates_v1': {
        'type': 'one-to-many'
      },
      'public_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v2': {
        'type': 'one-to-many'
      },
      'exams_v1': {
        'type': 'one-to-many'
      },
      'owner_v1': {
        'type': 'one-to-one'
      },
      'guide_levels_v1': {
        'type': 'one-to-many'
      },
      'owner_v2': {
        'type': 'one-to-one'
      },
      'guide_levels_v2': {
        'type': 'one-to-many'
      },
      'search_tags_v1': {
        'type': 'one-to-many'
      },
      'sections_v1': {
        'type': 'one-to-many'
      },
      'questions_v1': {
        'type': 'one-to-many'
      },
      'subject_v1': {
        'type': 'one-to-one'
      },
      'questions_v2': {
        'type': 'one-to-many'
      },
      'authoring_exams_v1': {
        'type': 'one-to-many'
      },
      'author_v1': {
        'type': 'one-to-one'
      },
      'subject_v2': {
        'type': 'one-to-one'
      },
      'questions_v3': {
        'type': 'one-to-many'
      },
      'draft_assignments_v1': {
        'type': 'one-to-many'
      },
      'assignments_v1': {
        'type': 'one-to-many'
      },
      'assignments_v2': {
        'type': 'one-to-many'
      },
      'authoring_questions_v1': {
        'type': 'one-to-many'
      },
      'authoring_subject_v1': {
        'type': 'one-to-one'
      },
      'assignments_v3': {
        'type': 'one-to-many'
      },
      'student_assignments_v1': {
        'type': 'one-to-many'
      },
      'author_user_v1': {
        'type': 'one-to-one'
      },
      'author_user_v2': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'student_assignments': Empty,
    'published_at': Empty,
    'file_upload': Empty,
    'authoring_subject': Empty,
    'authoring_questions': Empty,
    'assignments': Empty,
    'draft_assignments': Empty,
    'reorder_questions': Empty,
    'is_new': Empty,
    'author': Empty,
    'name': Empty,
    'authoring_exams': Empty,
    'subject': Empty,
    'questions': Empty,
    'subject_id': Empty,
    'sections': Empty,
    'status_email': Empty,
    'slug_id': Empty,
    'search_tags': Empty,
    'guide_levels': Empty,
    'status': Empty,
    'owner': Empty,
    'exams': Empty,
    'search_questions': Empty,
    'updated_at': Empty,
    'public_questions': Empty,
    'tags': Empty,
    'templates': Empty,
    'inserted_at': Empty,
    'status_message': Empty,
    'authoring_guide_levels': Empty,
    'status_code': Empty,
    'id': Empty,
    'difficulty': Empty,
    'relationshipMeta': Empty,
    'category': Empty,
    'author_user': Empty,
    'meta': new Map({
    'count_of_two_way_questions_published': Empty,
    'populated_metadata_fields': Empty,
    'count_of_passage_correction_questions_published': Empty,
    'owner_id': Empty,
    'count_of_easy_questions': Empty,
    'count_of_moderate_questions': Empty,
    'count_of_graphing_questions_published': Empty,
    'count_of_fill_in_the_blank_questions_published': Empty,
    'author_id': Empty,
    'count_of_snippet_selection_questions_published': Empty,
    'duplicate_alignment': Empty,
    'count_of_difficult_questions': Empty,
    'count_of_free_entry_questions_published': Empty,
    'count_of_multiple_choice_questions_published': Empty,
    'count_of_questions': Empty,
    'count_of_text_highlight_questions_published': Empty,
    'count_of_free_response_questions_published': Empty,
    'matches_tag_name': Empty,
    'forecasted_alignment': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'published_at': new Date(),
    'student_assignments': new List(),
    'file_upload': false,
    'name': '',
    'subject_id': '',
    'status_email': '',
    'slug_id': '',
    'status': '',
    'status_message': '',
    'id': '',
    'category': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'authoring_questions': new List(),
    'assignments': new List(),
    'draft_assignments': new List(),
    'reorder_questions': new List(),
    'authoring_exams': new List(),
    'templates': new List(),
    'authoring_subject': new Map(),
    'meta': new Map(),
    'is_new': false,
    'questions': new List(),
    'sections': new List(),
    'search_tags': new List(),
    'guide_levels': new List(),
    'exams': new List(),
    'search_questions': new List(),
    'public_questions': new List(),
    'tags': new List(),
    'authoring_guide_levels': new List(),
    'author': new Map(),
    'subject': new Map(),
    'owner': new Map(),
    'status_code': 0,
    'difficulty': 0,
    'relationshipMeta': new Map(),
    'author_user': new Map()
  }
);

export class AuthoringQuestionSetModelV1 extends GenericModel(ModelRecord, ModelConfig, 'AuthoringQuestionSetModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyAuthoringQuestionSetModelV1Model = _emptyAuthoringQuestionSetModelV1Model ? _emptyAuthoringQuestionSetModelV1Model : new AuthoringQuestionSetModelV1(DefaultModel);
    return _emptyAuthoringQuestionSetModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'AuthoringQuestionSetModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'authoring_question_set_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getCategory() {
    const value = this.getValueForAttribute('category');
    return value;
  }

  setCategory(value) {
    return this.setField('category', value);
  }

  /**
   * @type {integer}
   */
  getDifficulty() {
    const value = this.getValueForAttribute('difficulty');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  /**
   * @type {boolean}
   */
  isFileUpload() {
    const value = this.getValueForAttribute('file_upload');
    return value;
  }

  setFileUpload(value) {
    return this.setField('file_upload', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {boolean}
   */
  isNew() {
    const value = this.getValueForAttribute('is_new');
    return value;
  }

  setIsNew(value) {
    return this.setField('is_new', value);
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {datetime}
   */
  getPublishedAt() {
    const value = this.getValueForAttribute('published_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
  * @type {binary_id[]}
  */
  setReorderQuestions(value) {
    return this.setField('reorder_questions', value);
  }

  /**
   * @type {string}
   */
  getSlugId() {
    const value = this.getValueForAttribute('slug_id');
    return value;
  }

  setSlugId(value) {
    return this.setField('slug_id', value);
  }

  /**
   * @type {string}
   */
  getStatus() {
    const value = this.getValueForAttribute('status');
    return value;
  }

  setStatus(value) {
    return this.setField('status', value);
  }

  /**
   * @type {integer}
   */
  getStatusCode() {
    const value = this.getValueForAttribute('status_code');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  /**
   * @type {string}
   */
  getStatusEmail() {
    const value = this.getValueForAttribute('status_email');
    return value;
  }

  setStatusEmail(value) {
    return this.setField('status_email', value);
  }

  /**
   * @type {string}
   */
  getStatusMessage() {
    const value = this.getValueForAttribute('status_message');
    return value;
  }

  setStatusMessage(value) {
    return this.setField('status_message', value);
  }

  /**
   * @type {binary_id}
   */
  getSubjectId() {
    const value = this.getValueForAttribute('subject_id');
    return value;
  }

  setSubjectId(value) {
    return this.setField('subject_id', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getAuthorId() {
        return this.getMeta().getAuthorId();
      }
    
      getCountOfDifficultQuestions() {
        return this.getMeta().getCountOfDifficultQuestions();
      }
    
      getCountOfEasyQuestions() {
        return this.getMeta().getCountOfEasyQuestions();
      }
    
      getCountOfFillInTheBlankQuestionsPublished() {
        return this.getMeta().getCountOfFillInTheBlankQuestionsPublished();
      }
    
      getCountOfFreeEntryQuestionsPublished() {
        return this.getMeta().getCountOfFreeEntryQuestionsPublished();
      }
    
      getCountOfFreeResponseQuestionsPublished() {
        return this.getMeta().getCountOfFreeResponseQuestionsPublished();
      }
    
      getCountOfGraphingQuestionsPublished() {
        return this.getMeta().getCountOfGraphingQuestionsPublished();
      }
    
      getCountOfModerateQuestions() {
        return this.getMeta().getCountOfModerateQuestions();
      }
    
      getCountOfMultipleChoiceQuestionsPublished() {
        return this.getMeta().getCountOfMultipleChoiceQuestionsPublished();
      }
    
      getCountOfPassageCorrectionQuestionsPublished() {
        return this.getMeta().getCountOfPassageCorrectionQuestionsPublished();
      }
    
      getCountOfQuestions() {
        return this.getMeta().getCountOfQuestions();
      }
    
      getCountOfSnippetSelectionQuestionsPublished() {
        return this.getMeta().getCountOfSnippetSelectionQuestionsPublished();
      }
    
      getCountOfTextHighlightQuestionsPublished() {
        return this.getMeta().getCountOfTextHighlightQuestionsPublished();
      }
    
      getCountOfTwoWayQuestionsPublished() {
        return this.getMeta().getCountOfTwoWayQuestionsPublished();
      }
    
      isDuplicateAlignment() {
        return this.getMeta().isDuplicateAlignment();
      }
    
      getForecastedAlignment() {
        return this.getMeta().getForecastedAlignment();
      }
    
      isMatchesTagName() {
        return this.getMeta().isMatchesTagName();
      }
    
      getOwnerId() {
        return this.getMeta().getOwnerId();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {binary_id}
           */
          getAuthorId() {
            const value = meta.get('author_id');
            validate(value, 'author_id');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getCountOfDifficultQuestions() {
            const value = meta.get('count_of_difficult_questions');
            validate(value, 'count_of_difficult_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfEasyQuestions() {
            const value = meta.get('count_of_easy_questions');
            validate(value, 'count_of_easy_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfFillInTheBlankQuestionsPublished() {
            const value = meta.get('count_of_fill_in_the_blank_questions_published');
            validate(value, 'count_of_fill_in_the_blank_questions_published');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfFreeEntryQuestionsPublished() {
            const value = meta.get('count_of_free_entry_questions_published');
            validate(value, 'count_of_free_entry_questions_published');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfFreeResponseQuestionsPublished() {
            const value = meta.get('count_of_free_response_questions_published');
            validate(value, 'count_of_free_response_questions_published');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfGraphingQuestionsPublished() {
            const value = meta.get('count_of_graphing_questions_published');
            validate(value, 'count_of_graphing_questions_published');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfModerateQuestions() {
            const value = meta.get('count_of_moderate_questions');
            validate(value, 'count_of_moderate_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfMultipleChoiceQuestionsPublished() {
            const value = meta.get('count_of_multiple_choice_questions_published');
            validate(value, 'count_of_multiple_choice_questions_published');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfPassageCorrectionQuestionsPublished() {
            const value = meta.get('count_of_passage_correction_questions_published');
            validate(value, 'count_of_passage_correction_questions_published');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfQuestions() {
            const value = meta.get('count_of_questions');
            validate(value, 'count_of_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSnippetSelectionQuestionsPublished() {
            const value = meta.get('count_of_snippet_selection_questions_published');
            validate(value, 'count_of_snippet_selection_questions_published');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfTextHighlightQuestionsPublished() {
            const value = meta.get('count_of_text_highlight_questions_published');
            validate(value, 'count_of_text_highlight_questions_published');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfTwoWayQuestionsPublished() {
            const value = meta.get('count_of_two_way_questions_published');
            validate(value, 'count_of_two_way_questions_published');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isDuplicateAlignment() {
            const value = meta.get('duplicate_alignment');
            validate(value, 'duplicate_alignment');
            return value;
          },
  
          /**
           * @type {map[][]}
           */
          getForecastedAlignment() {
            const value = meta.get('forecasted_alignment');
            validate(value, 'forecasted_alignment');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isMatchesTagName() {
            const value = meta.get('matches_tag_name');
            validate(value, 'matches_tag_name');
            return value;
          },
  
          /**
           * @type {binary_id}
           */
          getOwnerId() {
            const value = meta.get('owner_id');
            validate(value, 'owner_id');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {student_assignment_v1 (many)}
   */
  getStudentAssignments() {
    const value = this.getValueForAttribute('student_assignments');
    return value;
  }

  // -- Relationship Fields: student_assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentAssignmentRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student_assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentAssignmentRelationshipGetters(rel));
    } else {
      return this.__withStudentAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_subject_v1 (one)}
   */
  getAuthoringSubject() {
    const value = this.getValueForAttribute('authoring_subject');
    return value;
  }

  // -- Relationship Fields: authoring_subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_question_v1 (many)}
   */
  getAuthoringQuestions() {
    const value = this.getValueForAttribute('authoring_questions');
    return value;
  }

  // -- Relationship Fields: authoring_questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {assignment_v3 (many)}
   */
  getAssignments() {
    const value = this.getValueForAttribute('assignments');
    return value;
  }

  // -- Relationship Fields: assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAssignmentRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAssignmentRelationshipGetters(rel));
    } else {
      return this.__withAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {draft_assignment_v1 (many)}
   */
  getDraftAssignments() {
    const value = this.getValueForAttribute('draft_assignments');
    return value;
  }

  // -- Relationship Fields: draft_assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withDraftAssignmentRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getDraftAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'draft_assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withDraftAssignmentRelationshipGetters(rel));
    } else {
      return this.__withDraftAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {author_v1 (one)}
   */
  getAuthor() {
    const value = this.getValueForAttribute('author');
    return value;
  }

  // -- Relationship Fields: author --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_exam_v1 (many)}
   */
  getAuthoringExams() {
    const value = this.getValueForAttribute('authoring_exams');
    return value;
  }

  // -- Relationship Fields: authoring_exams --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringExamRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringExamRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_exam'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringExamRelationshipGetters(rel));
    } else {
      return this.__withAuthoringExamRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {subject_v2 (one)}
   */
  getSubject() {
    const value = this.getValueForAttribute('subject');
    return value;
  }

  // -- Relationship Fields: subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_v3 (many)}
   */
  getQuestions() {
    const value = this.getValueForAttribute('questions');
    return value;
  }

  // -- Relationship Fields: questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {section_v1 (many)}
   */
  getSections() {
    const value = this.getValueForAttribute('sections');
    return value;
  }

  // -- Relationship Fields: sections --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSectionRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSectionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'section'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSectionRelationshipGetters(rel));
    } else {
      return this.__withSectionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {search_tag_v1 (many)}
   */
  getSearchTags() {
    const value = this.getValueForAttribute('search_tags');
    return value;
  }

  // -- Relationship Fields: search_tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_level_v2 (many)}
   */
  getGuideLevels() {
    const value = this.getValueForAttribute('guide_levels');
    return value;
  }

  // -- Relationship Fields: guide_levels --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withGuideLevelRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getGuideLevelRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'guide_level'], new Map());
    if (!id) {
      return data.map((rel) => this.__withGuideLevelRelationshipGetters(rel));
    } else {
      return this.__withGuideLevelRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {user_v2 (one)}
   */
  getOwner() {
    const value = this.getValueForAttribute('owner');
    return value;
  }

  // -- Relationship Fields: owner --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {exam_v1 (many)}
   */
  getExams() {
    const value = this.getValueForAttribute('exams');
    return value;
  }

  // -- Relationship Fields: exams --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withExamRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getExamRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'exam'], new Map());
    if (!id) {
      return data.map((rel) => this.__withExamRelationshipGetters(rel));
    } else {
      return this.__withExamRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {search_question_v2 (many)}
   */
  getSearchQuestions() {
    const value = this.getValueForAttribute('search_questions');
    return value;
  }

  // -- Relationship Fields: search_questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {public_question_v1 (many)}
   */
  getPublicQuestions() {
    const value = this.getValueForAttribute('public_questions');
    return value;
  }

  // -- Relationship Fields: public_questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {tag_v1 (many)}
   */
  getTags() {
    const value = this.getValueForAttribute('tags');
    return value;
  }

  // -- Relationship Fields: tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {template_v1 (many)}
   */
  getTemplates() {
    const value = this.getValueForAttribute('templates');
    return value;
  }

  // -- Relationship Fields: templates --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withTemplateRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getTemplateRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'template'], new Map());
    if (!id) {
      return data.map((rel) => this.__withTemplateRelationshipGetters(rel));
    } else {
      return this.__withTemplateRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_guide_level_v1 (many)}
   */
  getAuthoringGuideLevels() {
    const value = this.getValueForAttribute('authoring_guide_levels');
    return value;
  }

  // -- Relationship Fields: authoring_guide_levels --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringGuideLevelRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringGuideLevelRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_guide_level'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringGuideLevelRelationshipGetters(rel));
    } else {
      return this.__withAuthoringGuideLevelRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {user_v2 (one)}
   */
  getAuthorUser() {
    const value = this.getValueForAttribute('author_user');
    return value;
  }

  // -- Relationship Fields: author_user --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyAuthoringQuestionSetModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'category': null,
  'difficulty': null,
  'file_upload': null,
  'id': null,
  'inserted_at': null,
  'is_new': null,
  'meta.author_id': null,
  'meta.count_of_difficult_questions': null,
  'meta.count_of_easy_questions': null,
  'meta.count_of_fill_in_the_blank_questions_published': null,
  'meta.count_of_free_entry_questions_published': null,
  'meta.count_of_free_response_questions_published': null,
  'meta.count_of_graphing_questions_published': null,
  'meta.count_of_moderate_questions': null,
  'meta.count_of_multiple_choice_questions_published': null,
  'meta.count_of_passage_correction_questions_published': null,
  'meta.count_of_questions': null,
  'meta.count_of_snippet_selection_questions_published': null,
  'meta.count_of_text_highlight_questions_published': null,
  'meta.count_of_two_way_questions_published': null,
  'meta.duplicate_alignment': null,
  'meta.matches_tag_name': null,
  'meta.owner_id': null,
  'name': null,
  'published_at': null,
  'slug_id': null,
  'status': null,
  'status_code': null,
  'status_email': null,
  'status_message': null,
  'subject_id': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'assignments_v1': new Set(['associated']),
  'assignments_v2': new Set(['associated']),
  'assignments_v3': new Set(['associated']),
  'author_user_v1': new Set(['associated']),
  'author_user_v2': new Set(['associated']),
  'author_v1': new Set(['associated']),
  'authoring_exams_v1': new Set(['associated']),
  'authoring_guide_levels_v1': new Set(['associated']),
  'authoring_questions_v1': new Set(['associated']),
  'authoring_subject_v1': new Set(['associated']),
  'category': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'difficulty': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'draft_assignments_v1': new Set(['associated']),
  'exams_v1': new Set(['associated']),
  'file_upload': new Set(['exact','not','null']),
  'guide_levels_v1': new Set(['associated']),
  'guide_levels_v2': new Set(['associated']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'is_new': new Set(['exact','not','null']),
  'meta.author_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.count_of_difficult_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_easy_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_fill_in_the_blank_questions_published': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_free_entry_questions_published': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_free_response_questions_published': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_graphing_questions_published': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_moderate_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_multiple_choice_questions_published': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_passage_correction_questions_published': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_snippet_selection_questions_published': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_text_highlight_questions_published': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_two_way_questions_published': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.duplicate_alignment': new Set(['exact','not','null']),
  'meta.matches_tag_name': new Set(['exact','not','null']),
  'meta.owner_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.populated_metadata_fields': new Set(['null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'owner_v1': new Set(['associated']),
  'owner_v2': new Set(['associated']),
  'public_questions_v1': new Set(['associated']),
  'published_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'questions_v1': new Set(['associated']),
  'questions_v2': new Set(['associated']),
  'questions_v3': new Set(['associated']),
  'reorder_questions': new Set(['null']),
  'search_questions_v1': new Set(['associated']),
  'search_questions_v2': new Set(['associated']),
  'search_tags_v1': new Set(['associated']),
  'sections_v1': new Set(['associated']),
  'slug_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'status': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'status_code': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'status_email': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'status_message': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'student_assignments_v1': new Set(['associated']),
  'subject_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'subject_v1': new Set(['associated']),
  'subject_v2': new Set(['associated']),
  'tags_v1': new Set(['associated']),
  'templates_v1': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'alignment_details': 'meta.context.alignment_details',
    'answer_difficulty_counts': 'meta.context.authoring_question_set.answer_difficulty_counts',
    'answer_type_counts': 'meta.context.authoring_question_set.answer_type_counts',
    'duplicate_aligned': 'meta.context.guide_level.duplicate_aligned',
    'tag_name_search': 'meta.context.search.tag_name',
    'student_id': 'meta.context.student.id',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const AuthoringQuestionSet = AuthoringQuestionSetModelV1;
