/* eslint-disable */


// Programatically generated on Fri Apr 04 2025 16:13:15 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'search_questions_v2',
    'attributes': {
      'solution_document_stems': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': true
      },
      'solution_document': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': true
      },
      'document_stems': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': true
      },
      'document': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': true
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_question_set_v1': {
        'type': 'one-to-one'
      },
      'question_set_v1': {
        'type': 'one-to-one'
      },
      'search_standards_v1': {
        'type': 'one-to-many'
      },
      'search_standards_v2': {
        'type': 'one-to-many'
      },
      'search_tags_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'solution_document': Empty,
    'document_stems': Empty,
    'question_set': Empty,
    'solution_document_stems': Empty,
    'search_tags': Empty,
    'authoring_question_set': Empty,
    'updated_at': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'document': Empty,
    'relationshipMeta': Empty,
    'search_standards': Empty,
    'meta': new Map({
    'populated_metadata_fields': Empty,
    'solution_document_access': Empty,
    'solution_document_stems_access': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'id': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'search_tags': new List(),
    'search_standards': new List(),
    'solution_document': new Map(),
    'meta': new Map(),
    'document_stems': new Map(),
    'question_set': new Map(),
    'solution_document_stems': new Map(),
    'authoring_question_set': new Map(),
    'document': new Map(),
    'relationshipMeta': new Map()
  }
);

export class SearchQuestionModelV2 extends GenericModel(ModelRecord, ModelConfig, 'SearchQuestionModelV2') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptySearchQuestionModelV2Model = _emptySearchQuestionModelV2Model ? _emptySearchQuestionModelV2Model : new SearchQuestionModelV2(DefaultModel);
    return _emptySearchQuestionModelV2Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'SearchQuestionModelV2';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'search_question_v2';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getSolutionDocumentAccess() {
        return this.getMeta().getSolutionDocumentAccess();
      }
    
      getSolutionDocumentStemsAccess() {
        return this.getMeta().getSolutionDocumentStemsAccess();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {tsvector}
           */
          getSolutionDocumentAccess() {
            const value = meta.get('solution_document_access');
            validate(value, 'solution_document_access');
            return value;
          },
  
          /**
           * @type {tsvector}
           */
          getSolutionDocumentStemsAccess() {
            const value = meta.get('solution_document_stems_access');
            validate(value, 'solution_document_stems_access');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {search_standard_v2 (many)}
   */
  getSearchStandards() {
    const value = this.getValueForAttribute('search_standards');
    return value;
  }

  // -- Relationship Fields: search_standards --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {search_tag_v1 (many)}
   */
  getSearchTags() {
    const value = this.getValueForAttribute('search_tags');
    return value;
  }

  // -- Relationship Fields: search_tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_question_set_v1 (one)}
   */
  getAuthoringQuestionSet() {
    const value = this.getValueForAttribute('authoring_question_set');
    return value;
  }

  // -- Relationship Fields: authoring_question_set --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_set_v1 (one)}
   */
  getQuestionSet() {
    const value = this.getValueForAttribute('question_set');
    return value;
  }

  // -- Relationship Fields: question_set --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptySearchQuestionModelV2Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'id': null,
  'inserted_at': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_question_set_v1': new Set(['associated']),
  'document': new Set(['search_phrases']),
  'document_stems': new Set(['search_tokens']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.solution_document_access': new Set(['search_phrases']),
  'meta.solution_document_stems_access': new Set(['search_tokens']),
  'question_set_v1': new Set(['associated']),
  'search_standards_v1': new Set(['associated']),
  'search_standards_v2': new Set(['associated']),
  'search_tags_v1': new Set(['associated']),
  'solution_document': new Set(['search_phrases']),
  'solution_document_stems': new Set(['search_tokens']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'user_id': 'meta.context.user.id',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const SearchQuestion = SearchQuestionModelV2;
