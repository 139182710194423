/* eslint-disable */


// Programatically generated on Fri Apr 04 2025 16:13:15 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'assignments_v3',
    'attributes': {
      'template_id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'settings': {
        'type': 'map',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'instructions': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'assignment_type': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'status': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'group_id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'description': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'issuer_v1': {
        'type': 'one-to-one'
      },
      'issuer_v2': {
        'type': 'one-to-one'
      },
      'students_v1': {
        'type': 'one-to-many'
      },
      'students_v2': {
        'type': 'one-to-many'
      },
      'sections_v1': {
        'type': 'one-to-many'
      },
      'subject_v1': {
        'type': 'one-to-one'
      },
      'subject_v2': {
        'type': 'one-to-one'
      },
      'question_sets_v1': {
        'type': 'one-to-many'
      },
      'draft_guesses_v1': {
        'type': 'one-to-many'
      },
      'guesses_v1': {
        'type': 'one-to-many'
      },
      'authoring_subject_v1': {
        'type': 'one-to-one'
      },
      'student_classrooms_v1': {
        'type': 'one-to-many'
      },
      'authoring_question_sets_v1': {
        'type': 'one-to-many'
      },
      'teacher_v1': {
        'type': 'one-to-one'
      },
      'classrooms_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'classrooms': Empty,
    'template_id': Empty,
    'authoring_question_sets': Empty,
    'teacher': Empty,
    'settings': Empty,
    'student_classrooms': Empty,
    'authoring_subject': Empty,
    'guesses': Empty,
    'instructions': Empty,
    'draft_guesses': Empty,
    'question_sets': Empty,
    'name': Empty,
    'subject': Empty,
    'assignment_type': Empty,
    'sections': Empty,
    'status': Empty,
    'updated_at': Empty,
    'students': Empty,
    'issuer': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'group_id': Empty,
    'description': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'student_time_spent': Empty,
    'count_of_incomplete_students': Empty,
    'default_settings_allow_late_submissions': Empty,
    'student_settings_start_date': Empty,
    'populated_metadata_fields': Empty,
    'count_of_completed_students': Empty,
    'count_of_completed_late_students': Empty,
    'default_settings_start_date': Empty,
    'classroom_settings_due_date': Empty,
    'student_late': Empty,
    'is_self_assigned': Empty,
    'count_of_students': Empty,
    'student_count_of_guesses': Empty,
    'student_settings_due_date': Empty,
    'used_in_active_classroom': Empty,
    'curriculum_colors': Empty,
    'classroom_settings_allow_late_submissions': Empty,
    'count_of_questions': Empty,
    'average_grade': Empty,
    'default_settings_due_date': Empty,
    'student_submitted': Empty,
    'count_of_classrooms': Empty,
    'student_submitted_at': Empty,
    'classroom_settings_start_date': Empty,
    'student_count_of_correct_guesses': Empty,
    'classroom_names': Empty,
    'student_settings_allow_late_submissions': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'template_id': '',
    'instructions': '',
    'name': '',
    'assignment_type': '',
    'status': '',
    'id': '',
    'group_id': '',
    'description': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'classrooms': new List(),
    'authoring_question_sets': new List(),
    'student_classrooms': new List(),
    'guesses': new List(),
    'draft_guesses': new List(),
    'question_sets': new List(),
    'sections': new List(),
    'students': new List(),
    'teacher': new Map(),
    'settings': new Map(),
    'authoring_subject': new Map(),
    'meta': new Map(),
    'subject': new Map(),
    'issuer': new Map(),
    'relationshipMeta': new Map()
  }
);

export class AssignmentModelV3 extends GenericModel(ModelRecord, ModelConfig, 'AssignmentModelV3') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyAssignmentModelV3Model = _emptyAssignmentModelV3Model ? _emptyAssignmentModelV3Model : new AssignmentModelV3(DefaultModel);
    return _emptyAssignmentModelV3Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'AssignmentModelV3';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'assignment_v3';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getAssignmentType() {
    const value = this.getValueForAttribute('assignment_type');
    return value;
  }

  /**
   * @type {string}
   */
  getDescription() {
    const value = this.getValueForAttribute('description');
    return value;
  }

  setDescription(value) {
    return this.setField('description', value);
  }

  /**
   * @type {binary_id}
   */
  getGroupId() {
    const value = this.getValueForAttribute('group_id');
    return value;
  }

  setGroupId(value) {
    return this.setField('group_id', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getInstructions() {
    const value = this.getValueForAttribute('instructions');
    return value;
  }

  setInstructions(value) {
    return this.setField('instructions', value);
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {map}
   */
  getSettings() {
    const value = this.getValueForAttribute('settings');
    return value;
  }

  setSettings(value) {
    return this.setField('settings', value);
  }

  /**
   * @type {string}
   */
  getStatus() {
    const value = this.getValueForAttribute('status');
    return value;
  }

  setStatus(value) {
    return this.setField('status', value);
  }

  /**
   * @type {binary_id}
   */
  getTemplateId() {
    const value = this.getValueForAttribute('template_id');
    return value;
  }

  setTemplateId(value) {
    return this.setField('template_id', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getAverageGrade() {
        return this.getMeta().getAverageGrade();
      }
    
      getClassroomNames() {
        return this.getMeta().getClassroomNames();
      }
    
      isClassroomSettingsAllowLateSubmissions() {
        return this.getMeta().isClassroomSettingsAllowLateSubmissions();
      }
    
      getClassroomSettingsDueDate() {
        return this.getMeta().getClassroomSettingsDueDate();
      }
    
      getClassroomSettingsStartDate() {
        return this.getMeta().getClassroomSettingsStartDate();
      }
    
      getCountOfClassrooms() {
        return this.getMeta().getCountOfClassrooms();
      }
    
      getCountOfCompletedLateStudents() {
        return this.getMeta().getCountOfCompletedLateStudents();
      }
    
      getCountOfCompletedStudents() {
        return this.getMeta().getCountOfCompletedStudents();
      }
    
      getCountOfIncompleteStudents() {
        return this.getMeta().getCountOfIncompleteStudents();
      }
    
      getCountOfQuestions() {
        return this.getMeta().getCountOfQuestions();
      }
    
      getCountOfStudents() {
        return this.getMeta().getCountOfStudents();
      }
    
      getCurriculumColors() {
        return this.getMeta().getCurriculumColors();
      }
    
      isDefaultSettingsAllowLateSubmissions() {
        return this.getMeta().isDefaultSettingsAllowLateSubmissions();
      }
    
      getDefaultSettingsDueDate() {
        return this.getMeta().getDefaultSettingsDueDate();
      }
    
      getDefaultSettingsStartDate() {
        return this.getMeta().getDefaultSettingsStartDate();
      }
    
      isSelfAssigned() {
        return this.getMeta().isSelfAssigned();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getStudentCountOfCorrectGuesses() {
        return this.getMeta().getStudentCountOfCorrectGuesses();
      }
    
      getStudentCountOfGuesses() {
        return this.getMeta().getStudentCountOfGuesses();
      }
    
      isStudentLate() {
        return this.getMeta().isStudentLate();
      }
    
      isStudentSettingsAllowLateSubmissions() {
        return this.getMeta().isStudentSettingsAllowLateSubmissions();
      }
    
      getStudentSettingsDueDate() {
        return this.getMeta().getStudentSettingsDueDate();
      }
    
      getStudentSettingsStartDate() {
        return this.getMeta().getStudentSettingsStartDate();
      }
    
      isStudentSubmitted() {
        return this.getMeta().isStudentSubmitted();
      }
    
      getStudentSubmittedAt() {
        return this.getMeta().getStudentSubmittedAt();
      }
    
      getStudentTimeSpent() {
        return this.getMeta().getStudentTimeSpent();
      }
    
      isUsedInActiveClassroom() {
        return this.getMeta().isUsedInActiveClassroom();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {float}
           */
          getAverageGrade() {
            const value = meta.get('average_grade');
            validate(value, 'average_grade');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
          /**
           * @type {string}
           */
          getClassroomNames() {
            const value = meta.get('classroom_names');
            validate(value, 'classroom_names');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isClassroomSettingsAllowLateSubmissions() {
            const value = meta.get('classroom_settings_allow_late_submissions');
            validate(value, 'classroom_settings_allow_late_submissions');
            return value;
          },
  
          /**
           * @type {datetime}
           */
          getClassroomSettingsDueDate() {
            const value = meta.get('classroom_settings_due_date');
            validate(value, 'classroom_settings_due_date');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {datetime}
           */
          getClassroomSettingsStartDate() {
            const value = meta.get('classroom_settings_start_date');
            validate(value, 'classroom_settings_start_date');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfClassrooms() {
            const value = meta.get('count_of_classrooms');
            validate(value, 'count_of_classrooms');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfCompletedLateStudents() {
            const value = meta.get('count_of_completed_late_students');
            validate(value, 'count_of_completed_late_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfCompletedStudents() {
            const value = meta.get('count_of_completed_students');
            validate(value, 'count_of_completed_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfIncompleteStudents() {
            const value = meta.get('count_of_incomplete_students');
            validate(value, 'count_of_incomplete_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfQuestions() {
            const value = meta.get('count_of_questions');
            validate(value, 'count_of_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfStudents() {
            const value = meta.get('count_of_students');
            validate(value, 'count_of_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string}
           */
          getCurriculumColors() {
            const value = meta.get('curriculum_colors');
            validate(value, 'curriculum_colors');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isDefaultSettingsAllowLateSubmissions() {
            const value = meta.get('default_settings_allow_late_submissions');
            validate(value, 'default_settings_allow_late_submissions');
            return value;
          },
  
          /**
           * @type {datetime}
           */
          getDefaultSettingsDueDate() {
            const value = meta.get('default_settings_due_date');
            validate(value, 'default_settings_due_date');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {datetime}
           */
          getDefaultSettingsStartDate() {
            const value = meta.get('default_settings_start_date');
            validate(value, 'default_settings_start_date');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {boolean}
           */
          isSelfAssigned() {
            const value = meta.get('is_self_assigned');
            validate(value, 'is_self_assigned');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getStudentCountOfCorrectGuesses() {
            const value = meta.get('student_count_of_correct_guesses');
            validate(value, 'student_count_of_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentCountOfGuesses() {
            const value = meta.get('student_count_of_guesses');
            validate(value, 'student_count_of_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isStudentLate() {
            const value = meta.get('student_late');
            validate(value, 'student_late');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isStudentSettingsAllowLateSubmissions() {
            const value = meta.get('student_settings_allow_late_submissions');
            validate(value, 'student_settings_allow_late_submissions');
            return value;
          },
  
          /**
           * @type {datetime}
           */
          getStudentSettingsDueDate() {
            const value = meta.get('student_settings_due_date');
            validate(value, 'student_settings_due_date');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {datetime}
           */
          getStudentSettingsStartDate() {
            const value = meta.get('student_settings_start_date');
            validate(value, 'student_settings_start_date');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {boolean}
           */
          isStudentSubmitted() {
            const value = meta.get('student_submitted');
            validate(value, 'student_submitted');
            return value;
          },
  
          /**
           * @type {datetime}
           */
          getStudentSubmittedAt() {
            const value = meta.get('student_submitted_at');
            validate(value, 'student_submitted_at');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentTimeSpent() {
            const value = meta.get('student_time_spent');
            validate(value, 'student_time_spent');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isUsedInActiveClassroom() {
            const value = meta.get('used_in_active_classroom');
            validate(value, 'used_in_active_classroom');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {classroom_v1 (many)}
   */
  getClassrooms() {
    const value = this.getValueForAttribute('classrooms');
    return value;
  }

  // -- Relationship Fields: classrooms --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withClassroomRelationshipGetters(data) {
    return {
      getSettings() {
        return data.get('settings', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getClassroomRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'classroom'], new Map());
    if (!id) {
      return data.map((rel) => this.__withClassroomRelationshipGetters(rel));
    } else {
      return this.__withClassroomRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_question_set_v1 (many)}
   */
  getAuthoringQuestionSets() {
    const value = this.getValueForAttribute('authoring_question_sets');
    return value;
  }

  // -- Relationship Fields: authoring_question_sets --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringQuestionSetRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringQuestionSetRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_question_set'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringQuestionSetRelationshipGetters(rel));
    } else {
      return this.__withAuthoringQuestionSetRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {teacher_v1 (one)}
   */
  getTeacher() {
    const value = this.getValueForAttribute('teacher');
    return value;
  }

  // -- Relationship Fields: teacher --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {student_classroom_v1 (many)}
   */
  getStudentClassrooms() {
    const value = this.getValueForAttribute('student_classrooms');
    return value;
  }

  // -- Relationship Fields: student_classrooms --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentClassroomRelationshipGetters(data) {
    return {
      getSettings() {
        return data.get('settings', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentClassroomRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student_classroom'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentClassroomRelationshipGetters(rel));
    } else {
      return this.__withStudentClassroomRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_subject_v1 (one)}
   */
  getAuthoringSubject() {
    const value = this.getValueForAttribute('authoring_subject');
    return value;
  }

  // -- Relationship Fields: authoring_subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guess_v1 (many)}
   */
  getGuesses() {
    const value = this.getValueForAttribute('guesses');
    return value;
  }

  // -- Relationship Fields: guesses --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {draft_guess_v1 (many)}
   */
  getDraftGuesses() {
    const value = this.getValueForAttribute('draft_guesses');
    return value;
  }

  // -- Relationship Fields: draft_guesses --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_set_v1 (many)}
   */
  getQuestionSets() {
    const value = this.getValueForAttribute('question_sets');
    return value;
  }

  // -- Relationship Fields: question_sets --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withQuestionSetRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getQuestionSetRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'question_set'], new Map());
    if (!id) {
      return data.map((rel) => this.__withQuestionSetRelationshipGetters(rel));
    } else {
      return this.__withQuestionSetRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {subject_v2 (one)}
   */
  getSubject() {
    const value = this.getValueForAttribute('subject');
    return value;
  }

  // -- Relationship Fields: subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {section_v1 (many)}
   */
  getSections() {
    const value = this.getValueForAttribute('sections');
    return value;
  }

  // -- Relationship Fields: sections --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {student_v2 (many)}
   */
  getStudents() {
    const value = this.getValueForAttribute('students');
    return value;
  }

  // -- Relationship Fields: students --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentRelationshipGetters(data) {
    return {
      getDeletedAt() {
        return data.get('deleted_at', null);
      },
      getSettings() {
        return data.get('settings', null);
      },
      getStartTime() {
        return data.get('start_time', null);
      },
      getSubmitted() {
        return data.get('submitted', null);
      },
      getSentEmailAt() {
        return data.get('sent_email_at', null);
      },
      getConfirmedSentEmailAt() {
        return data.get('confirmed_sent_email_at', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentRelationshipGetters(rel));
    } else {
      return this.__withStudentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {user_v2 (one)}
   */
  getIssuer() {
    const value = this.getValueForAttribute('issuer');
    return value;
  }

  // -- Relationship Fields: issuer --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyAssignmentModelV3Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'assignment_type': null,
  'description': null,
  'group_id': null,
  'id': null,
  'inserted_at': null,
  'instructions': null,
  'meta.classroom_names': null,
  'meta.classroom_settings_allow_late_submissions': null,
  'meta.classroom_settings_due_date': null,
  'meta.classroom_settings_start_date': null,
  'meta.count_of_classrooms': null,
  'meta.count_of_completed_late_students': null,
  'meta.count_of_completed_students': null,
  'meta.count_of_incomplete_students': null,
  'meta.count_of_questions': null,
  'meta.count_of_students': null,
  'meta.curriculum_colors': null,
  'meta.default_settings_allow_late_submissions': null,
  'meta.default_settings_due_date': null,
  'meta.default_settings_start_date': null,
  'meta.is_self_assigned': null,
  'meta.student_count_of_correct_guesses': null,
  'meta.student_count_of_guesses': null,
  'meta.student_late': null,
  'meta.student_settings_allow_late_submissions': null,
  'meta.student_settings_due_date': null,
  'meta.student_settings_start_date': null,
  'meta.student_submitted': null,
  'meta.student_submitted_at': null,
  'meta.student_time_spent': null,
  'meta.used_in_active_classroom': null,
  'name': null,
  'status': null,
  'template_id': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'assignment_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'authoring_question_sets_v1': new Set(['associated']),
  'authoring_subject_v1': new Set(['associated']),
  'classrooms_v1': new Set(['associated']),
  'description': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'draft_guesses_v1': new Set(['associated']),
  'group_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'guesses_v1': new Set(['associated']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'instructions': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'issuer_v1': new Set(['associated']),
  'issuer_v2': new Set(['associated']),
  'meta.classroom_names': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.classroom_settings_allow_late_submissions': new Set(['exact','not','null']),
  'meta.classroom_settings_due_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_settings_start_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_classrooms': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_completed_late_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_completed_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_incomplete_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.curriculum_colors': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.default_settings_allow_late_submissions': new Set(['exact','not','null']),
  'meta.default_settings_due_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.default_settings_start_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.is_self_assigned': new Set(['exact','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.student_count_of_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_count_of_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_late': new Set(['exact','not','null']),
  'meta.student_settings_allow_late_submissions': new Set(['exact','not','null']),
  'meta.student_settings_due_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_settings_start_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_submitted': new Set(['exact','not','null']),
  'meta.student_submitted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_time_spent': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.used_in_active_classroom': new Set(['exact','not','null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'question_sets_v1': new Set(['associated']),
  'sections_v1': new Set(['associated']),
  'settings': new Set([]),
  'status': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'student_classrooms_v1': new Set(['associated']),
  'students_v1': new Set(['associated']),
  'students_v2': new Set(['associated']),
  'subject_v1': new Set(['associated']),
  'subject_v2': new Set(['associated']),
  'teacher_v1': new Set(['associated']),
  'template_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'assignment_stats': 'meta.context.assignment.stats',
    'classroom_id': 'meta.context.classroom.id',
    'classroom_settings': 'meta.context.classroom.settings',
    'curriculum_colors': 'meta.context.curriculum.colors',
    'default_settings': 'meta.context.default.settings',
    'student_id': 'meta.context.student.id',
    'student_settings': 'meta.context.student.settings',
    'used_in_active_classroom': 'meta.context.used_in_active_classroom',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Assignment = AssignmentModelV3;
