/* eslint-disable */


// Programatically generated on Fri Apr 04 2025 16:13:15 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'authoring_questions_v1',
    'attributes': {
      'solution_document': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': true
      },
      'dropdowns': {
        'type': 'map[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'points': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'question_type_attributes': {
        'type': 'map',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'prompt': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'document_stems': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': true
      },
      'snippet_prompt': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'preferred_permutation': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'position': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'translate_supplements': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inputs': {
        'type': 'map[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'sample': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'header_right': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'rubric_type': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'solution_document_stems': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': true
      },
      'rubric': {
        'type': 'map',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'translate': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'slug_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'highlight_prompt': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'plagiarism_report_link': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'question_sub_type': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'solution_text': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'question_type': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'title': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'rows': {
        'type': 'map[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'header_left': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'dropdown_text': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'document': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': true
      },
      'difficulty': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'region_indices': {
        'type': 'map',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'uncorrected_text': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'is_select_multiple': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'options': {
        'type': 'map[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'plagiarism_checked_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_guide_levels_v1': {
        'type': 'one-to-many'
      },
      'supplements_v1': {
        'type': 'one-to-many'
      },
      'tags_v1': {
        'type': 'one-to-many'
      },
      'user_feedback_v1': {
        'type': 'one-to-many'
      },
      'standards_v1': {
        'type': 'one-to-many'
      },
      'authoring_supplements_v1': {
        'type': 'one-to-many'
      },
      'guide_levels_v1': {
        'type': 'one-to-many'
      },
      'authoring_question_set_v1': {
        'type': 'one-to-one'
      },
      'guide_levels_v2': {
        'type': 'one-to-many'
      },
      'search_tags_v1': {
        'type': 'one-to-many'
      },
      'authoring_feedback_v1': {
        'type': 'one-to-many'
      },
      'question_set_v1': {
        'type': 'one-to-one'
      },
      'draft_guesses_v1': {
        'type': 'one-to-many'
      },
      'authoring_labels_v1': {
        'type': 'one-to-many'
      },
      'guesses_v1': {
        'type': 'one-to-many'
      },
      'labels_v1': {
        'type': 'one-to-many'
      },
      'search_standards_v1': {
        'type': 'one-to-many'
      },
      'search_standards_v2': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'solution_document': Empty,
    'dropdowns': Empty,
    'points': Empty,
    'question_type_attributes': Empty,
    'prompt': Empty,
    'guesses': Empty,
    'document_stems': Empty,
    'snippet_prompt': Empty,
    'draft_guesses': Empty,
    'authoring_labels': Empty,
    'preferred_permutation': Empty,
    'question_set': Empty,
    'position': Empty,
    'translate_supplements': Empty,
    'inputs': Empty,
    'sample': Empty,
    'header_right': Empty,
    'rubric_type': Empty,
    'solution_document_stems': Empty,
    'authoring_feedback': Empty,
    'rubric': Empty,
    'translate': Empty,
    'slug_id': Empty,
    'highlight_prompt': Empty,
    'search_tags': Empty,
    'authoring_question_set': Empty,
    'plagiarism_report_link': Empty,
    'guide_levels': Empty,
    'authoring_supplements': Empty,
    'question_sub_type': Empty,
    'status': Empty,
    'solution_text': Empty,
    'question_type': Empty,
    'standards': Empty,
    'updated_at': Empty,
    'title': Empty,
    'tags': Empty,
    'user_feedback': Empty,
    'rows': Empty,
    'header_left': Empty,
    'inserted_at': Empty,
    'authoring_guide_levels': Empty,
    'supplements': Empty,
    'dropdown_text': Empty,
    'id': Empty,
    'document': Empty,
    'difficulty': Empty,
    'region_indices': Empty,
    'uncorrected_text': Empty,
    'relationshipMeta': Empty,
    'is_select_multiple': Empty,
    'options': Empty,
    'plagiarism_checked_at': Empty,
    'search_standards': Empty,
    'labels': Empty,
    'meta': new Map({
    'count_of_correct_guesses': Empty,
    'count_of_incorrect_guesses': Empty,
    'duplicate_alignment': Empty,
    'how_others_answered': Empty,
    'populated_metadata_fields': Empty,
    'question_or_status_updated_at': Empty,
    'sample': Empty,
    'solution_text': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'dropdowns': new List(),
    'solution_document': new Map(),
    'points': 0,
    'prompt': '',
    'snippet_prompt': '',
    'preferred_permutation': '',
    'sample': '',
    'header_right': '',
    'rubric_type': '',
    'slug_id': '',
    'highlight_prompt': '',
    'plagiarism_report_link': '',
    'question_sub_type': '',
    'status': '',
    'solution_text': '',
    'question_type': '',
    'title': '',
    'header_left': '',
    'dropdown_text': '',
    'id': '',
    'uncorrected_text': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'plagiarism_checked_at': new Date(),
    'guesses': new List(),
    'draft_guesses': new List(),
    'authoring_labels': new List(),
    'inputs': new List(),
    'authoring_feedback': new List(),
    'search_tags': new List(),
    'guide_levels': new List(),
    'authoring_supplements': new List(),
    'standards': new List(),
    'tags': new List(),
    'user_feedback': new List(),
    'rows': new List(),
    'authoring_guide_levels': new List(),
    'options': new List(),
    'search_standards': new List(),
    'labels': new List(),
    'question_type_attributes': new Map(),
    'meta': new Map(),
    'document_stems': new Map(),
    'question_set': new Map(),
    'position': 0,
    'translate_supplements': false,
    'solution_document_stems': new Map(),
    'rubric': new Map(),
    'translate': false,
    'supplements': new List(),
    'authoring_question_set': new Map(),
    'document': new Map(),
    'difficulty': 0,
    'region_indices': new Map(),
    'relationshipMeta': new Map(),
    'is_select_multiple': false
  }
);

export class AuthoringQuestionModelV1 extends GenericModel(ModelRecord, ModelConfig, 'AuthoringQuestionModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyAuthoringQuestionModelV1Model = _emptyAuthoringQuestionModelV1Model ? _emptyAuthoringQuestionModelV1Model : new AuthoringQuestionModelV1(DefaultModel);
    return _emptyAuthoringQuestionModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'AuthoringQuestionModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'authoring_question_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {integer}
   */
  getDifficulty() {
    const value = this.getValueForAttribute('difficulty');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setDifficulty(value) {
    return this.setField('difficulty', value);
  }

  /**
   * @type {string}
   */
  getDropdownText() {
    const value = this.getValueForAttribute('dropdown_text');
    return value;
  }

  setDropdownText(value) {
    return this.setField('dropdown_text', value);
  }

  /**
   * @type {map[]}
   */
  getDropdowns() {
    const value = this.getValueForAttribute('dropdowns');
    return value;
  }

  setDropdowns(value) {
    return this.setField('dropdowns', value);
  }

  /**
   * @type {string}
   */
  getHeaderLeft() {
    const value = this.getValueForAttribute('header_left');
    return value;
  }

  setHeaderLeft(value) {
    return this.setField('header_left', value);
  }

  /**
   * @type {string}
   */
  getHeaderRight() {
    const value = this.getValueForAttribute('header_right');
    return value;
  }

  setHeaderRight(value) {
    return this.setField('header_right', value);
  }

  /**
   * @type {string}
   */
  getHighlightPrompt() {
    const value = this.getValueForAttribute('highlight_prompt');
    return value;
  }

  setHighlightPrompt(value) {
    return this.setField('highlight_prompt', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {map[]}
   */
  getInputs() {
    const value = this.getValueForAttribute('inputs');
    return value;
  }

  setInputs(value) {
    return this.setField('inputs', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {boolean}
   */
  isSelectMultiple() {
    const value = this.getValueForAttribute('is_select_multiple');
    return value;
  }

  setIsSelectMultiple(value) {
    return this.setField('is_select_multiple', value);
  }

  /**
   * @type {map[]}
   */
  getOptions() {
    const value = this.getValueForAttribute('options');
    return value;
  }

  setOptions(value) {
    return this.setField('options', value);
  }

  /**
   * @type {datetime}
   */
  getPlagiarismCheckedAt() {
    const value = this.getValueForAttribute('plagiarism_checked_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getPlagiarismReportLink() {
    const value = this.getValueForAttribute('plagiarism_report_link');
    return value;
  }

  /**
   * @type {integer}
   */
  getPoints() {
    const value = this.getValueForAttribute('points');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  /**
   * @type {integer}
   */
  getPosition() {
    const value = this.getValueForAttribute('position');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  /**
   * @type {binary_id}
   */
  getPreferredPermutation() {
    const value = this.getValueForAttribute('preferred_permutation');
    return value;
  }

  setPreferredPermutation(value) {
    return this.setField('preferred_permutation', value);
  }

  /**
   * @type {string}
   */
  getPrompt() {
    const value = this.getValueForAttribute('prompt');
    return value;
  }

  setPrompt(value) {
    return this.setField('prompt', value);
  }

  /**
   * @type {string}
   */
  getQuestionSubType() {
    const value = this.getValueForAttribute('question_sub_type');
    return value;
  }

  setQuestionSubType(value) {
    return this.setField('question_sub_type', value);
  }

  /**
   * @type {string}
   */
  getQuestionType() {
    const value = this.getValueForAttribute('question_type');
    return value;
  }

  setQuestionType(value) {
    return this.setField('question_type', value);
  }

  /**
   * @type {map}
   */
  getQuestionTypeAttributes() {
    const value = this.getValueForAttribute('question_type_attributes');
    return value;
  }

  setQuestionTypeAttributes(value) {
    return this.setField('question_type_attributes', value);
  }

  /**
   * @type {map}
   */
  getRegionIndices() {
    const value = this.getValueForAttribute('region_indices');
    return value;
  }

  /**
   * @type {map[]}
   */
  getRows() {
    const value = this.getValueForAttribute('rows');
    return value;
  }

  setRows(value) {
    return this.setField('rows', value);
  }

  /**
   * @type {map}
   */
  getRubric() {
    const value = this.getValueForAttribute('rubric');
    return value;
  }

  setRubric(value) {
    return this.setField('rubric', value);
  }

  /**
   * @type {string}
   */
  getRubricType() {
    const value = this.getValueForAttribute('rubric_type');
    return value;
  }

  /**
   * @type {string}
   */
  getSample() {
    const value = this.getValueForAttribute('sample');
    return value;
  }

  setSample(value) {
    return this.setField('sample', value);
  }

  /**
   * @type {string}
   */
  getSlugId() {
    const value = this.getValueForAttribute('slug_id');
    return value;
  }

  /**
   * @type {string}
   */
  getSnippetPrompt() {
    const value = this.getValueForAttribute('snippet_prompt');
    return value;
  }

  setSnippetPrompt(value) {
    return this.setField('snippet_prompt', value);
  }

  /**
   * @type {string}
   */
  getSolutionText() {
    const value = this.getValueForAttribute('solution_text');
    return value;
  }

  setSolutionText(value) {
    return this.setField('solution_text', value);
  }

  /**
   * @type {string}
   */
  getStatus() {
    const value = this.getValueForAttribute('status');
    return value;
  }

  setStatus(value) {
    return this.setField('status', value);
  }

  /**
   * @type {string}
   */
  getTitle() {
    const value = this.getValueForAttribute('title');
    return value;
  }

  setTitle(value) {
    return this.setField('title', value);
  }

  /**
   * @type {boolean}
   */
  isTranslate() {
    const value = this.getValueForAttribute('translate');
    return value;
  }

  setTranslate(value) {
    return this.setField('translate', value);
  }

  /**
   * @type {boolean}
   */
  isTranslateSupplements() {
    const value = this.getValueForAttribute('translate_supplements');
    return value;
  }

  setTranslateSupplements(value) {
    return this.setField('translate_supplements', value);
  }

  /**
   * @type {string}
   */
  getUncorrectedText() {
    const value = this.getValueForAttribute('uncorrected_text');
    return value;
  }

  setUncorrectedText(value) {
    return this.setField('uncorrected_text', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getCountOfCorrectGuesses() {
        return this.getMeta().getCountOfCorrectGuesses();
      }
    
      getCountOfIncorrectGuesses() {
        return this.getMeta().getCountOfIncorrectGuesses();
      }
    
      isDuplicateAlignment() {
        return this.getMeta().isDuplicateAlignment();
      }
    
      getHowOthersAnswered() {
        return this.getMeta().getHowOthersAnswered();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getQuestionOrStatusUpdatedAt() {
        return this.getMeta().getQuestionOrStatusUpdatedAt();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getCountOfCorrectGuesses() {
            const value = meta.get('count_of_correct_guesses');
            validate(value, 'count_of_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfIncorrectGuesses() {
            const value = meta.get('count_of_incorrect_guesses');
            validate(value, 'count_of_incorrect_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isDuplicateAlignment() {
            const value = meta.get('duplicate_alignment');
            validate(value, 'duplicate_alignment');
            return value;
          },
  
          /**
           * @type {map}
           */
          getHowOthersAnswered() {
            const value = meta.get('how_others_answered');
            validate(value, 'how_others_answered');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {datetime}
           */
          getQuestionOrStatusUpdatedAt() {
            const value = meta.get('question_or_status_updated_at');
            validate(value, 'question_or_status_updated_at');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {string}
           */
          getSample() {
            const value = meta.get('sample');
            validate(value, 'sample');
            return value;
          },
  
          /**
           * @type {string}
           */
          getSolutionText() {
            const value = meta.get('solution_text');
            validate(value, 'solution_text');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {guess_v1 (many)}
   */
  getGuesses() {
    const value = this.getValueForAttribute('guesses');
    return value;
  }

  // -- Relationship Fields: guesses --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {draft_guess_v1 (many)}
   */
  getDraftGuesses() {
    const value = this.getValueForAttribute('draft_guesses');
    return value;
  }

  // -- Relationship Fields: draft_guesses --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_label_v1 (many)}
   */
  getAuthoringLabels() {
    const value = this.getValueForAttribute('authoring_labels');
    return value;
  }

  // -- Relationship Fields: authoring_labels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_set_v1 (one)}
   */
  getQuestionSet() {
    const value = this.getValueForAttribute('question_set');
    return value;
  }

  // -- Relationship Fields: question_set --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_feedback_v1 (many)}
   */
  getAuthoringFeedback() {
    const value = this.getValueForAttribute('authoring_feedback');
    return value;
  }

  // -- Relationship Fields: authoring_feedback --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {search_tag_v1 (many)}
   */
  getSearchTags() {
    const value = this.getValueForAttribute('search_tags');
    return value;
  }

  // -- Relationship Fields: search_tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_question_set_v1 (one)}
   */
  getAuthoringQuestionSet() {
    const value = this.getValueForAttribute('authoring_question_set');
    return value;
  }

  // -- Relationship Fields: authoring_question_set --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_level_v2 (many)}
   */
  getGuideLevels() {
    const value = this.getValueForAttribute('guide_levels');
    return value;
  }

  // -- Relationship Fields: guide_levels --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withGuideLevelRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getGuideLevelRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'guide_level'], new Map());
    if (!id) {
      return data.map((rel) => this.__withGuideLevelRelationshipGetters(rel));
    } else {
      return this.__withGuideLevelRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_supplement_v1 (many)}
   */
  getAuthoringSupplements() {
    const value = this.getValueForAttribute('authoring_supplements');
    return value;
  }

  // -- Relationship Fields: authoring_supplements --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringSupplementRelationshipGetters(data) {
    return {
      getContentOverride() {
        return data.get('content_override', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringSupplementRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_supplement'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringSupplementRelationshipGetters(rel));
    } else {
      return this.__withAuthoringSupplementRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {standard_v1 (many)}
   */
  getStandards() {
    const value = this.getValueForAttribute('standards');
    return value;
  }

  // -- Relationship Fields: standards --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {tag_v1 (many)}
   */
  getTags() {
    const value = this.getValueForAttribute('tags');
    return value;
  }

  // -- Relationship Fields: tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {user_feedback_v1 (many)}
   */
  getUserFeedback() {
    const value = this.getValueForAttribute('user_feedback');
    return value;
  }

  // -- Relationship Fields: user_feedback --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_guide_level_v1 (many)}
   */
  getAuthoringGuideLevels() {
    const value = this.getValueForAttribute('authoring_guide_levels');
    return value;
  }

  // -- Relationship Fields: authoring_guide_levels --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringGuideLevelRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringGuideLevelRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_guide_level'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringGuideLevelRelationshipGetters(rel));
    } else {
      return this.__withAuthoringGuideLevelRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {supplement_v1 (many)}
   */
  getSupplements() {
    const value = this.getValueForAttribute('supplements');
    return value;
  }

  // -- Relationship Fields: supplements --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSupplementRelationshipGetters(data) {
    return {
      getContentOverride() {
        return data.get('content_override', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSupplementRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'supplement'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSupplementRelationshipGetters(rel));
    } else {
      return this.__withSupplementRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {search_standard_v2 (many)}
   */
  getSearchStandards() {
    const value = this.getValueForAttribute('search_standards');
    return value;
  }

  // -- Relationship Fields: search_standards --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {label_v1 (many)}
   */
  getLabels() {
    const value = this.getValueForAttribute('labels');
    return value;
  }

  // -- Relationship Fields: labels --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyAuthoringQuestionModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'difficulty': null,
  'dropdown_text': null,
  'header_left': null,
  'header_right': null,
  'highlight_prompt': null,
  'id': null,
  'inserted_at': null,
  'is_select_multiple': null,
  'meta.count_of_correct_guesses': null,
  'meta.count_of_incorrect_guesses': null,
  'meta.duplicate_alignment': null,
  'meta.question_or_status_updated_at': null,
  'meta.sample': null,
  'meta.solution_text': null,
  'plagiarism_checked_at': null,
  'plagiarism_report_link': null,
  'points': null,
  'position': null,
  'preferred_permutation': null,
  'prompt': null,
  'question_sub_type': null,
  'question_type': null,
  'rubric_type': null,
  'sample': null,
  'slug_id': null,
  'snippet_prompt': null,
  'solution_text': null,
  'status': null,
  'title': null,
  'translate': null,
  'translate_supplements': null,
  'uncorrected_text': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_feedback_v1': new Set(['associated']),
  'authoring_guide_levels_v1': new Set(['associated']),
  'authoring_labels_v1': new Set(['associated']),
  'authoring_question_set_v1': new Set(['associated']),
  'authoring_supplements_v1': new Set(['associated']),
  'difficulty': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'document': new Set(['search_phrases']),
  'document_stems': new Set(['search_tokens']),
  'draft_guesses_v1': new Set(['associated']),
  'dropdown_text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'dropdowns': new Set(['null']),
  'guesses_v1': new Set(['associated']),
  'guide_levels_v1': new Set(['associated']),
  'guide_levels_v2': new Set(['associated']),
  'header_left': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'header_right': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'highlight_prompt': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inputs': new Set(['null']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'is_select_multiple': new Set(['exact','not','null']),
  'labels_v1': new Set(['associated']),
  'meta.count_of_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_incorrect_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.duplicate_alignment': new Set(['exact','not','null']),
  'meta.how_others_answered': new Set(['null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.question_or_status_updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sample': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.solution_text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'options': new Set(['null']),
  'plagiarism_checked_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'plagiarism_report_link': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'points': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'position': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'preferred_permutation': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'prompt': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'question_set_v1': new Set(['associated']),
  'question_sub_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'question_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'question_type_attributes': new Set(['null']),
  'region_indices': new Set(['null']),
  'rows': new Set(['null']),
  'rubric': new Set(['not','null']),
  'rubric_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'sample': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'search_standards_v1': new Set(['associated']),
  'search_standards_v2': new Set(['associated']),
  'search_tags_v1': new Set(['associated']),
  'slug_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'snippet_prompt': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'solution_document': new Set(['search_phrases']),
  'solution_document_stems': new Set(['search_tokens']),
  'solution_text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'standards_v1': new Set(['associated']),
  'status': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix']),
  'supplements_v1': new Set(['associated']),
  'tags_v1': new Set(['associated']),
  'title': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'translate': new Set(['exact','not','null']),
  'translate_supplements': new Set(['exact','not','null']),
  'uncorrected_text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'user_feedback_v1': new Set(['associated']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'duplicate_aligned': 'meta.context.guide_level.duplicate_aligned',
    'status_tracking': 'meta.context.status_tracking',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const AuthoringQuestion = AuthoringQuestionModelV1;
