/* eslint-disable */


// Programatically generated on Fri Apr 04 2025 16:13:15 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'students_v2',
    'attributes': {
      'username': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'last_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'first_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'email': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'school_v1': {
        'type': 'one-to-one'
      },
      'school_v2': {
        'type': 'one-to-one'
      },
      'school_v3': {
        'type': 'one-to-one'
      },
      'school_v4': {
        'type': 'one-to-one'
      },
      'school_v5': {
        'type': 'one-to-one'
      },
      'authoring_subjects_v1': {
        'type': 'one-to-many'
      },
      'sections_v1': {
        'type': 'one-to-many'
      },
      'authoring_exams_v1': {
        'type': 'one-to-many'
      },
      'managed_user_v1': {
        'type': 'one-to-one'
      },
      'user_v1': {
        'type': 'one-to-one'
      },
      'user_v2': {
        'type': 'one-to-one'
      },
      'subjects_v1': {
        'type': 'one-to-many'
      },
      'draft_guesses_v1': {
        'type': 'one-to-many'
      },
      'subjects_v2': {
        'type': 'one-to-many'
      },
      'assignments_v1': {
        'type': 'one-to-many'
      },
      'guesses_v1': {
        'type': 'one-to-many'
      },
      'assignments_v2': {
        'type': 'one-to-many'
      },
      'assignments_v3': {
        'type': 'one-to-many'
      },
      'student_classrooms_v1': {
        'type': 'one-to-many'
      },
      'student_assignments_v1': {
        'type': 'one-to-many'
      },
      'classrooms_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'classrooms': Empty,
    'student_assignments': Empty,
    'student_classrooms': Empty,
    'guesses': Empty,
    'assignments': Empty,
    'draft_guesses': Empty,
    'subjects': Empty,
    'managed_user': Empty,
    'user': Empty,
    'authoring_exams': Empty,
    'sections': Empty,
    'authoring_subjects': Empty,
    'last_name': Empty,
    'school': Empty,
    'username': Empty,
    'updated_at': Empty,
    'inserted_at': Empty,
    'first_name': Empty,
    'id': Empty,
    'email': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'is_managed_user': Empty,
    'assignment_info': Empty,
    'school_subject_breakdown': Empty,
    'count_of_school_teachers': Empty,
    'populated_metadata_fields': Empty,
    'sum_of_guesses_points_earned': Empty,
    'assignment_count_of_incorrect_guesses': Empty,
    'school_subject_names': Empty,
    'assignment_count_of_correct_guesses': Empty,
    'section_count_of_incorrect_guesses': Empty,
    'count_of_guesses': Empty,
    'assignment_time_spent': Empty,
    'is_active_managed_user': Empty,
    'section_count_of_correct_guesses': Empty,
    'count_of_school_guesses': Empty,
    'count_of_school_classrooms': Empty,
    'count_of_school_assignments': Empty,
    'max_attempt_number': Empty,
    'section_time_spent': Empty,
    'latest_guess_timestamp': Empty,
    'count_of_school_incorrect_guesses': Empty,
    'count_of_correct_guesses': Empty,
    'sum_of_guesses_time_elapsed': Empty,
    'count_of_unanswered_questions': Empty,
    'count_of_school_correct_guesses': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'last_name': '',
    'username': '',
    'first_name': '',
    'id': '',
    'email': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'classrooms': new List(),
    'student_assignments': new List(),
    'student_classrooms': new List(),
    'guesses': new List(),
    'assignments': new List(),
    'draft_guesses': new List(),
    'subjects': new List(),
    'authoring_exams': new List(),
    'sections': new List(),
    'authoring_subjects': new List(),
    'meta': new Map(),
    'managed_user': new Map(),
    'user': new Map(),
    'school': new Map(),
    'relationshipMeta': new Map()
  }
);

export class StudentModelV2 extends GenericModel(ModelRecord, ModelConfig, 'StudentModelV2') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyStudentModelV2Model = _emptyStudentModelV2Model ? _emptyStudentModelV2Model : new StudentModelV2(DefaultModel);
    return _emptyStudentModelV2Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'StudentModelV2';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'student_v2';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getEmail() {
    const value = this.getValueForAttribute('email');
    return value;
  }

  /**
   * @type {string}
   */
  getFirstName() {
    const value = this.getValueForAttribute('first_name');
    return value;
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getLastName() {
    const value = this.getValueForAttribute('last_name');
    return value;
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUsername() {
    const value = this.getValueForAttribute('username');
    return value;
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getAssignmentCountOfCorrectGuesses() {
        return this.getMeta().getAssignmentCountOfCorrectGuesses();
      }
    
      getAssignmentCountOfIncorrectGuesses() {
        return this.getMeta().getAssignmentCountOfIncorrectGuesses();
      }
    
      getAssignmentInfo() {
        return this.getMeta().getAssignmentInfo();
      }
    
      getAssignmentTimeSpent() {
        return this.getMeta().getAssignmentTimeSpent();
      }
    
      getCountOfCorrectGuesses() {
        return this.getMeta().getCountOfCorrectGuesses();
      }
    
      getCountOfGuesses() {
        return this.getMeta().getCountOfGuesses();
      }
    
      getCountOfSchoolAssignments() {
        return this.getMeta().getCountOfSchoolAssignments();
      }
    
      getCountOfSchoolClassrooms() {
        return this.getMeta().getCountOfSchoolClassrooms();
      }
    
      getCountOfSchoolCorrectGuesses() {
        return this.getMeta().getCountOfSchoolCorrectGuesses();
      }
    
      getCountOfSchoolGuesses() {
        return this.getMeta().getCountOfSchoolGuesses();
      }
    
      getCountOfSchoolIncorrectGuesses() {
        return this.getMeta().getCountOfSchoolIncorrectGuesses();
      }
    
      getCountOfSchoolTeachers() {
        return this.getMeta().getCountOfSchoolTeachers();
      }
    
      getCountOfUnansweredQuestions() {
        return this.getMeta().getCountOfUnansweredQuestions();
      }
    
      isActiveManagedUser() {
        return this.getMeta().isActiveManagedUser();
      }
    
      isManagedUser() {
        return this.getMeta().isManagedUser();
      }
    
      getLatestGuessTimestamp() {
        return this.getMeta().getLatestGuessTimestamp();
      }
    
      getMaxAttemptNumber() {
        return this.getMeta().getMaxAttemptNumber();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getSchoolSubjectBreakdown() {
        return this.getMeta().getSchoolSubjectBreakdown();
      }
    
      getSchoolSubjectNames() {
        return this.getMeta().getSchoolSubjectNames();
      }
    
      getSectionCountOfCorrectGuesses() {
        return this.getMeta().getSectionCountOfCorrectGuesses();
      }
    
      getSectionCountOfIncorrectGuesses() {
        return this.getMeta().getSectionCountOfIncorrectGuesses();
      }
    
      getSectionTimeSpent() {
        return this.getMeta().getSectionTimeSpent();
      }
    
      getSumOfGuessesPointsEarned() {
        return this.getMeta().getSumOfGuessesPointsEarned();
      }
    
      getSumOfGuessesTimeElapsed() {
        return this.getMeta().getSumOfGuessesTimeElapsed();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getAssignmentCountOfCorrectGuesses() {
            const value = meta.get('assignment_count_of_correct_guesses');
            validate(value, 'assignment_count_of_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getAssignmentCountOfIncorrectGuesses() {
            const value = meta.get('assignment_count_of_incorrect_guesses');
            validate(value, 'assignment_count_of_incorrect_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {map}
           */
          getAssignmentInfo() {
            const value = meta.get('assignment_info');
            validate(value, 'assignment_info');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getAssignmentTimeSpent() {
            const value = meta.get('assignment_time_spent');
            validate(value, 'assignment_time_spent');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfCorrectGuesses() {
            const value = meta.get('count_of_correct_guesses');
            validate(value, 'count_of_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfGuesses() {
            const value = meta.get('count_of_guesses');
            validate(value, 'count_of_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolAssignments() {
            const value = meta.get('count_of_school_assignments');
            validate(value, 'count_of_school_assignments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolClassrooms() {
            const value = meta.get('count_of_school_classrooms');
            validate(value, 'count_of_school_classrooms');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolCorrectGuesses() {
            const value = meta.get('count_of_school_correct_guesses');
            validate(value, 'count_of_school_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolGuesses() {
            const value = meta.get('count_of_school_guesses');
            validate(value, 'count_of_school_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolIncorrectGuesses() {
            const value = meta.get('count_of_school_incorrect_guesses');
            validate(value, 'count_of_school_incorrect_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolTeachers() {
            const value = meta.get('count_of_school_teachers');
            validate(value, 'count_of_school_teachers');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfUnansweredQuestions() {
            const value = meta.get('count_of_unanswered_questions');
            validate(value, 'count_of_unanswered_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isActiveManagedUser() {
            const value = meta.get('is_active_managed_user');
            validate(value, 'is_active_managed_user');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isManagedUser() {
            const value = meta.get('is_managed_user');
            validate(value, 'is_managed_user');
            return value;
          },
  
          /**
           * @type {datetime}
           */
          getLatestGuessTimestamp() {
            const value = meta.get('latest_guess_timestamp');
            validate(value, 'latest_guess_timestamp');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {integer}
           */
          getMaxAttemptNumber() {
            const value = meta.get('max_attempt_number');
            validate(value, 'max_attempt_number');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {map}
           */
          getSchoolSubjectBreakdown() {
            const value = meta.get('school_subject_breakdown');
            validate(value, 'school_subject_breakdown');
            return value;
          },
  
          /**
           * @type {string}
           */
          getSchoolSubjectNames() {
            const value = meta.get('school_subject_names');
            validate(value, 'school_subject_names');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getSectionCountOfCorrectGuesses() {
            const value = meta.get('section_count_of_correct_guesses');
            validate(value, 'section_count_of_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSectionCountOfIncorrectGuesses() {
            const value = meta.get('section_count_of_incorrect_guesses');
            validate(value, 'section_count_of_incorrect_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSectionTimeSpent() {
            const value = meta.get('section_time_spent');
            validate(value, 'section_time_spent');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfGuessesPointsEarned() {
            const value = meta.get('sum_of_guesses_points_earned');
            validate(value, 'sum_of_guesses_points_earned');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfGuessesTimeElapsed() {
            const value = meta.get('sum_of_guesses_time_elapsed');
            validate(value, 'sum_of_guesses_time_elapsed');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {classroom_v1 (many)}
   */
  getClassrooms() {
    const value = this.getValueForAttribute('classrooms');
    return value;
  }

  // -- Relationship Fields: classrooms --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withClassroomRelationshipGetters(data) {
    return {
      getDeletedAt() {
        return data.get('deleted_at', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getClassroomRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'classroom'], new Map());
    if (!id) {
      return data.map((rel) => this.__withClassroomRelationshipGetters(rel));
    } else {
      return this.__withClassroomRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {student_assignment_v1 (many)}
   */
  getStudentAssignments() {
    const value = this.getValueForAttribute('student_assignments');
    return value;
  }

  // -- Relationship Fields: student_assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentAssignmentRelationshipGetters(data) {
    return {
      getDeletedAt() {
        return data.get('deleted_at', null);
      },
      getSettings() {
        return data.get('settings', null);
      },
      getStartTime() {
        return data.get('start_time', null);
      },
      getSubmitted() {
        return data.get('submitted', null);
      },
      getSentEmailAt() {
        return data.get('sent_email_at', null);
      },
      getConfirmedSentEmailAt() {
        return data.get('confirmed_sent_email_at', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student_assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentAssignmentRelationshipGetters(rel));
    } else {
      return this.__withStudentAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {student_classroom_v1 (many)}
   */
  getStudentClassrooms() {
    const value = this.getValueForAttribute('student_classrooms');
    return value;
  }

  // -- Relationship Fields: student_classrooms --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentClassroomRelationshipGetters(data) {
    return {
      getDeletedAt() {
        return data.get('deleted_at', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentClassroomRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student_classroom'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentClassroomRelationshipGetters(rel));
    } else {
      return this.__withStudentClassroomRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {guess_v1 (many)}
   */
  getGuesses() {
    const value = this.getValueForAttribute('guesses');
    return value;
  }

  // -- Relationship Fields: guesses --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {assignment_v3 (many)}
   */
  getAssignments() {
    const value = this.getValueForAttribute('assignments');
    return value;
  }

  // -- Relationship Fields: assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAssignmentRelationshipGetters(data) {
    return {
      getDeletedAt() {
        return data.get('deleted_at', null);
      },
      getSettings() {
        return data.get('settings', null);
      },
      getStartTime() {
        return data.get('start_time', null);
      },
      getSubmitted() {
        return data.get('submitted', null);
      },
      getSentEmailAt() {
        return data.get('sent_email_at', null);
      },
      getConfirmedSentEmailAt() {
        return data.get('confirmed_sent_email_at', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAssignmentRelationshipGetters(rel));
    } else {
      return this.__withAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {draft_guess_v1 (many)}
   */
  getDraftGuesses() {
    const value = this.getValueForAttribute('draft_guesses');
    return value;
  }

  // -- Relationship Fields: draft_guesses --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subject_v2 (many)}
   */
  getSubjects() {
    const value = this.getValueForAttribute('subjects');
    return value;
  }

  // -- Relationship Fields: subjects --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSubjectRelationshipGetters(data) {
    return {
      getMonth() {
        return data.get('month', null);
      },
      getDay() {
        return data.get('day', null);
      },
      getYear() {
        return data.get('year', null);
      },
      getCorrect() {
        return data.get('correct', null);
      },
      getTimeElapsed() {
        return data.get('time_elapsed', null);
      },
      getIncorrect() {
        return data.get('incorrect', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSubjectRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'subject'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSubjectRelationshipGetters(rel));
    } else {
      return this.__withSubjectRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {managed_user_v1 (one)}
   */
  getManagedUser() {
    const value = this.getValueForAttribute('managed_user');
    return value;
  }

  // -- Relationship Fields: managed_user --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {user_v2 (one)}
   */
  getUser() {
    const value = this.getValueForAttribute('user');
    return value;
  }

  // -- Relationship Fields: user --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_exam_v1 (many)}
   */
  getAuthoringExams() {
    const value = this.getValueForAttribute('authoring_exams');
    return value;
  }

  // -- Relationship Fields: authoring_exams --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringExamRelationshipGetters(data) {
    return {
      getDeletedAt() {
        return data.get('deleted_at', null);
      },
      getSettings() {
        return data.get('settings', null);
      },
      getStartTime() {
        return data.get('start_time', null);
      },
      getSubmitted() {
        return data.get('submitted', null);
      },
      getSentEmailAt() {
        return data.get('sent_email_at', null);
      },
      getConfirmedSentEmailAt() {
        return data.get('confirmed_sent_email_at', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringExamRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_exam'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringExamRelationshipGetters(rel));
    } else {
      return this.__withAuthoringExamRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {section_v1 (many)}
   */
  getSections() {
    const value = this.getValueForAttribute('sections');
    return value;
  }

  // -- Relationship Fields: sections --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSectionRelationshipGetters(data) {
    return {
      getDeletedAt() {
        return data.get('deleted_at', null);
      },
      getStartTime() {
        return data.get('start_time', null);
      },
      getSubmittedAt() {
        return data.get('submitted_at', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSectionRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'section'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSectionRelationshipGetters(rel));
    } else {
      return this.__withSectionRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_subject_v1 (many)}
   */
  getAuthoringSubjects() {
    const value = this.getValueForAttribute('authoring_subjects');
    return value;
  }

  // -- Relationship Fields: authoring_subjects --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringSubjectRelationshipGetters(data) {
    return {
      getMonth() {
        return data.get('month', null);
      },
      getDay() {
        return data.get('day', null);
      },
      getYear() {
        return data.get('year', null);
      },
      getCorrect() {
        return data.get('correct', null);
      },
      getTimeElapsed() {
        return data.get('time_elapsed', null);
      },
      getIncorrect() {
        return data.get('incorrect', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringSubjectRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_subject'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringSubjectRelationshipGetters(rel));
    } else {
      return this.__withAuthoringSubjectRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {school_v5 (one)}
   */
  getSchool() {
    const value = this.getValueForAttribute('school');
    return value;
  }

  // -- Relationship Fields: school --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyStudentModelV2Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'email': null,
  'first_name': null,
  'id': null,
  'inserted_at': null,
  'last_name': null,
  'meta.assignment_count_of_correct_guesses': null,
  'meta.assignment_count_of_incorrect_guesses': null,
  'meta.assignment_time_spent': null,
  'meta.count_of_correct_guesses': null,
  'meta.count_of_guesses': null,
  'meta.count_of_school_assignments': null,
  'meta.count_of_school_classrooms': null,
  'meta.count_of_school_correct_guesses': null,
  'meta.count_of_school_guesses': null,
  'meta.count_of_school_incorrect_guesses': null,
  'meta.count_of_school_teachers': null,
  'meta.count_of_unanswered_questions': null,
  'meta.is_active_managed_user': null,
  'meta.is_managed_user': null,
  'meta.latest_guess_timestamp': null,
  'meta.max_attempt_number': null,
  'meta.school_subject_names': null,
  'meta.section_count_of_correct_guesses': null,
  'meta.section_count_of_incorrect_guesses': null,
  'meta.section_time_spent': null,
  'meta.sum_of_guesses_points_earned': null,
  'meta.sum_of_guesses_time_elapsed': null,
  'updated_at': null,
  'username': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'assignments_v1': new Set(['associated']),
  'assignments_v2': new Set(['associated']),
  'assignments_v3': new Set(['associated']),
  'authoring_exams_v1': new Set(['associated']),
  'authoring_subjects_v1': new Set(['associated']),
  'classrooms_v1': new Set(['associated']),
  'draft_guesses_v1': new Set(['associated']),
  'email': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'first_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'guesses_v1': new Set(['associated']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'last_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'managed_user_v1': new Set(['associated']),
  'meta.assignment_count_of_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.assignment_count_of_incorrect_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.assignment_time_spent': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_assignments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_classrooms': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_incorrect_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_teachers': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_unanswered_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.is_active_managed_user': new Set(['exact','not','null']),
  'meta.is_managed_user': new Set(['exact','not','null']),
  'meta.latest_guess_timestamp': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.max_attempt_number': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.school_subject_names': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.section_count_of_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.section_count_of_incorrect_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.section_time_spent': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_guesses_points_earned': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_guesses_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'school_v1': new Set(['associated']),
  'school_v2': new Set(['associated']),
  'school_v3': new Set(['associated']),
  'school_v4': new Set(['associated']),
  'school_v5': new Set(['associated']),
  'sections_v1': new Set(['associated']),
  'student_assignments_v1': new Set(['associated']),
  'student_classrooms_v1': new Set(['associated']),
  'subjects_v1': new Set(['associated']),
  'subjects_v2': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'user_v1': new Set(['associated']),
  'user_v2': new Set(['associated']),
  'username': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'assignment_id': 'meta.context.assignment.id',
    'assignment_info': 'meta.context.assignment_info',
    'classroom_id': 'meta.context.classroom.id',
    'classroom_insights': 'meta.context.classroom.insights',
    'classroom_statuses': 'meta.context.classroom.statuses',
    'csv_headers': 'meta.context.csv_headers',
    'end_date': 'meta.context.end_date',
    'attempt_number': 'meta.context.guess.attempt_number',
    'most_recent': 'meta.context.guess.most_recent',
    'guide_level_id': 'meta.context.guide_level.id',
    'label_id': 'meta.context.label.id',
    'question_id': 'meta.context.question.id',
    'school_id': 'meta.context.school.id',
    'school_administrator_dashboard': 'meta.context.school_administrator.dashboard',
    'section_id': 'meta.context.section.id',
    'show_is_active_managed_user': 'meta.context.show_is_active_managed_user',
    'show_is_managed_user': 'meta.context.show_is_managed_user',
    'start_date': 'meta.context.start_date',
    'subject_breakdown': 'meta.context.subject.breakdown',
    'subject_id': 'meta.context.subject.id',
    'subject_ids': 'meta.context.subject.ids',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Student = StudentModelV2;
