/* eslint-disable */


// Programatically generated on Fri Apr 04 2025 16:13:15 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'labels_v1',
    'attributes': {
      'text': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'category': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'public_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v1': {
        'type': 'one-to-many'
      },
      'search_questions_v2': {
        'type': 'one-to-many'
      },
      'questions_v1': {
        'type': 'one-to-many'
      },
      'subject_v1': {
        'type': 'one-to-one'
      },
      'questions_v2': {
        'type': 'one-to-many'
      },
      'subject_v2': {
        'type': 'one-to-one'
      },
      'questions_v3': {
        'type': 'one-to-many'
      },
      'authoring_questions_v1': {
        'type': 'one-to-many'
      },
      'authoring_subject_v1': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'authoring_subject': Empty,
    'authoring_questions': Empty,
    'name': Empty,
    'subject': Empty,
    'text': Empty,
    'questions': Empty,
    'search_questions': Empty,
    'updated_at': Empty,
    'public_questions': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'relationshipMeta': Empty,
    'category': Empty,
    'meta': new Map({
    'student_time_spent': Empty,
    'sum_of_classroom_guesses_time_elapsed': Empty,
    'populated_metadata_fields': Empty,
    'count_of_student_questions_unanswered': Empty,
    'count_of_classroom_incorrect_guesses': Empty,
    'count_of_classroom_questions': Empty,
    'count_of_student_questions_answered': Empty,
    'avg_of_classroom_guesses_time_elapsed': Empty,
    'count_of_classroom_correct_guesses': Empty,
    'count_of_classroom_questions_unanswered': Empty,
    'student_avg_time_spent': Empty,
    'count_of_classroom_questions_answerable': Empty,
    'count_of_questions': Empty,
    'count_of_student_correct_attempts': Empty,
    'sum_of_classroom_guesses_points_earned': Empty,
    'count_of_student_incorrect_attempts': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'name': '',
    'text': '',
    'id': '',
    'category': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'authoring_questions': new List(),
    'questions': new List(),
    'search_questions': new List(),
    'public_questions': new List(),
    'authoring_subject': new Map(),
    'meta': new Map(),
    'subject': new Map(),
    'relationshipMeta': new Map()
  }
);

export class LabelModelV1 extends GenericModel(ModelRecord, ModelConfig, 'LabelModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyLabelModelV1Model = _emptyLabelModelV1Model ? _emptyLabelModelV1Model : new LabelModelV1(DefaultModel);
    return _emptyLabelModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'LabelModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'label_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getCategory() {
    const value = this.getValueForAttribute('category');
    return value;
  }

  setCategory(value) {
    return this.setField('category', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {string}
   */
  getText() {
    const value = this.getValueForAttribute('text');
    return value;
  }

  setText(value) {
    return this.setField('text', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getAvgOfClassroomGuessesTimeElapsed() {
        return this.getMeta().getAvgOfClassroomGuessesTimeElapsed();
      }
    
      getCountOfClassroomCorrectGuesses() {
        return this.getMeta().getCountOfClassroomCorrectGuesses();
      }
    
      getCountOfClassroomIncorrectGuesses() {
        return this.getMeta().getCountOfClassroomIncorrectGuesses();
      }
    
      getCountOfClassroomQuestions() {
        return this.getMeta().getCountOfClassroomQuestions();
      }
    
      getCountOfClassroomQuestionsAnswerable() {
        return this.getMeta().getCountOfClassroomQuestionsAnswerable();
      }
    
      getCountOfClassroomQuestionsUnanswered() {
        return this.getMeta().getCountOfClassroomQuestionsUnanswered();
      }
    
      getCountOfQuestions() {
        return this.getMeta().getCountOfQuestions();
      }
    
      getCountOfStudentCorrectAttempts() {
        return this.getMeta().getCountOfStudentCorrectAttempts();
      }
    
      getCountOfStudentIncorrectAttempts() {
        return this.getMeta().getCountOfStudentIncorrectAttempts();
      }
    
      getCountOfStudentQuestionsAnswered() {
        return this.getMeta().getCountOfStudentQuestionsAnswered();
      }
    
      getCountOfStudentQuestionsUnanswered() {
        return this.getMeta().getCountOfStudentQuestionsUnanswered();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getStudentAvgTimeSpent() {
        return this.getMeta().getStudentAvgTimeSpent();
      }
    
      getStudentTimeSpent() {
        return this.getMeta().getStudentTimeSpent();
      }
    
      getSumOfClassroomGuessesPointsEarned() {
        return this.getMeta().getSumOfClassroomGuessesPointsEarned();
      }
    
      getSumOfClassroomGuessesTimeElapsed() {
        return this.getMeta().getSumOfClassroomGuessesTimeElapsed();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {float}
           */
          getAvgOfClassroomGuessesTimeElapsed() {
            const value = meta.get('avg_of_classroom_guesses_time_elapsed');
            validate(value, 'avg_of_classroom_guesses_time_elapsed');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfClassroomCorrectGuesses() {
            const value = meta.get('count_of_classroom_correct_guesses');
            validate(value, 'count_of_classroom_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfClassroomIncorrectGuesses() {
            const value = meta.get('count_of_classroom_incorrect_guesses');
            validate(value, 'count_of_classroom_incorrect_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfClassroomQuestions() {
            const value = meta.get('count_of_classroom_questions');
            validate(value, 'count_of_classroom_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfClassroomQuestionsAnswerable() {
            const value = meta.get('count_of_classroom_questions_answerable');
            validate(value, 'count_of_classroom_questions_answerable');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfClassroomQuestionsUnanswered() {
            const value = meta.get('count_of_classroom_questions_unanswered');
            validate(value, 'count_of_classroom_questions_unanswered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfQuestions() {
            const value = meta.get('count_of_questions');
            validate(value, 'count_of_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfStudentCorrectAttempts() {
            const value = meta.get('count_of_student_correct_attempts');
            validate(value, 'count_of_student_correct_attempts');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfStudentIncorrectAttempts() {
            const value = meta.get('count_of_student_incorrect_attempts');
            validate(value, 'count_of_student_incorrect_attempts');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfStudentQuestionsAnswered() {
            const value = meta.get('count_of_student_questions_answered');
            validate(value, 'count_of_student_questions_answered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfStudentQuestionsUnanswered() {
            const value = meta.get('count_of_student_questions_unanswered');
            validate(value, 'count_of_student_questions_unanswered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {float}
           */
          getStudentAvgTimeSpent() {
            const value = meta.get('student_avg_time_spent');
            validate(value, 'student_avg_time_spent');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
          /**
           * @type {integer}
           */
          getStudentTimeSpent() {
            const value = meta.get('student_time_spent');
            validate(value, 'student_time_spent');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfClassroomGuessesPointsEarned() {
            const value = meta.get('sum_of_classroom_guesses_points_earned');
            validate(value, 'sum_of_classroom_guesses_points_earned');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfClassroomGuessesTimeElapsed() {
            const value = meta.get('sum_of_classroom_guesses_time_elapsed');
            validate(value, 'sum_of_classroom_guesses_time_elapsed');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {authoring_question_v1 (many)}
   */
  getAuthoringQuestions() {
    const value = this.getValueForAttribute('authoring_questions');
    return value;
  }

  // -- Relationship Fields: authoring_questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {public_question_v1 (many)}
   */
  getPublicQuestions() {
    const value = this.getValueForAttribute('public_questions');
    return value;
  }

  // -- Relationship Fields: public_questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_v3 (many)}
   */
  getQuestions() {
    const value = this.getValueForAttribute('questions');
    return value;
  }

  // -- Relationship Fields: questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {search_question_v2 (many)}
   */
  getSearchQuestions() {
    const value = this.getValueForAttribute('search_questions');
    return value;
  }

  // -- Relationship Fields: search_questions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_subject_v1 (one)}
   */
  getAuthoringSubject() {
    const value = this.getValueForAttribute('authoring_subject');
    return value;
  }

  // -- Relationship Fields: authoring_subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subject_v2 (one)}
   */
  getSubject() {
    const value = this.getValueForAttribute('subject');
    return value;
  }

  // -- Relationship Fields: subject --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyLabelModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'category': null,
  'id': null,
  'inserted_at': null,
  'meta.avg_of_classroom_guesses_time_elapsed': null,
  'meta.count_of_classroom_correct_guesses': null,
  'meta.count_of_classroom_incorrect_guesses': null,
  'meta.count_of_classroom_questions': null,
  'meta.count_of_classroom_questions_answerable': null,
  'meta.count_of_classroom_questions_unanswered': null,
  'meta.count_of_questions': null,
  'meta.count_of_student_correct_attempts': null,
  'meta.count_of_student_incorrect_attempts': null,
  'meta.count_of_student_questions_answered': null,
  'meta.count_of_student_questions_unanswered': null,
  'meta.student_avg_time_spent': null,
  'meta.student_time_spent': null,
  'meta.sum_of_classroom_guesses_points_earned': null,
  'meta.sum_of_classroom_guesses_time_elapsed': null,
  'name': null,
  'text': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_questions_v1': new Set(['associated']),
  'authoring_subject_v1': new Set(['associated']),
  'category': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.avg_of_classroom_guesses_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_classroom_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_classroom_incorrect_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_classroom_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_classroom_questions_answerable': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_classroom_questions_unanswered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_student_correct_attempts': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_student_incorrect_attempts': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_student_questions_answered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_student_questions_unanswered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.student_avg_time_spent': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.student_time_spent': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_classroom_guesses_points_earned': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_classroom_guesses_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'public_questions_v1': new Set(['associated']),
  'questions_v1': new Set(['associated']),
  'questions_v2': new Set(['associated']),
  'questions_v3': new Set(['associated']),
  'search_questions_v1': new Set(['associated']),
  'search_questions_v2': new Set(['associated']),
  'subject_v1': new Set(['associated']),
  'subject_v2': new Set(['associated']),
  'text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'assignment_id': 'meta.context.assignment.id',
    'classroom_id': 'meta.context.classroom.id',
    'classroom_insights': 'meta.context.classroom.insights',
    'csv_headers': 'meta.context.csv_headers',
    'end_date': 'meta.context.end_date',
    'attempt_number': 'meta.context.guess.attempt_number',
    'most_recent': 'meta.context.guess.most_recent',
    'guide_level_id': 'meta.context.guide_level.id',
    'label_counts': 'meta.context.label.counts',
    'start_date': 'meta.context.start_date',
    'student_id': 'meta.context.student.id',
    'subject_id': 'meta.context.subject.id',
    'subject_ids': 'meta.context.subject.ids',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Label = LabelModelV1;
